import { useEffect, useState } from "react";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "../../../../../components";
import {
  EtiquetasService,
  GrupoProdutosService,
} from "../../../../../services";
import { responseErros } from "../../../../../utils";
import { InputErros, value } from "../../../../../helpers";

const grupoProdutosService = new GrupoProdutosService();
const etiquetasService = new EtiquetasService();

const FiltrosConsultaFiscalStep = ({
  filtros,
  setFiltros,
  filtrarTodosProdutos,
  setFiltrarTodosProdutos,
}) => {
  const [grupoProdutoList, setGrupoProdutoList] = useState([]);
  const [etiquetas, setEtiquetas] = useState([]);
  const [listaEtiquetasInicial, setListaEtiquetasInicial] = useState([]);
  const [inputErro, setInputErro] = useState([]);
  const inputErros = new InputErros(inputErro, setInputErro);

  const buscarGruposProduto = () => {
    const filtros = {
      nonPaginated: true,
    };
    grupoProdutosService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setGrupoProdutoList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarEtiquetas = async function () {
    const filtros = {
      nonPaginated: true,
      ativado: true,
      tipo: "PRODUTO",
    };
    const result = await etiquetasService.getAll(filtros);
    if (!result.isAxiosError) {
      setEtiquetas(result.data);
      setListaEtiquetasInicial(result.data);
    } else {
      responseErros(result);
    }
  };

  const onChangeAutocomplete = (id, value) => {
    setFiltros({
      ...filtros,
      [id]: value ? value.id : undefined,
    });
  };

  const handleChangeCheckbox = (event) => {
    if (event.target.checked) {
      setFiltros({});
    }
    setFiltrarTodosProdutos(event.target.checked);
  };

  useEffect(() => {
    buscarGruposProduto();
    buscarEtiquetas();
  }, []);

  const onChangeMultiple = (
    valores,
    name,
    listaInicial,
    setListaAutocomplete
  ) => {
    if (!valores?.length) {
      resetarFiltrosEtiqueta(name, listaInicial, setListaAutocomplete);
      return;
    }
    setFiltros((prevState) => ({
      ...prevState,
      [name]: valores.map(({ id }) => id),
    }));
    updateListaEtiqueta(valores, listaInicial, setListaAutocomplete);
  };

  const updateListaEtiqueta = (valores, listaInicial, setListaAutocomplete) => {
    const listaTemp = listaInicial.filter(
      (status) => !valores.some((updateStatus) => updateStatus.id === status.id)
    );
    setListaAutocomplete(listaTemp);
  };

  function resetarFiltrosEtiqueta(name, listaInicial, setListaAutocomplete) {
    setFiltros((prevState) => {
      const prevStateTemp = { ...prevState };
      delete prevStateTemp[name];
      return prevStateTemp;
    });
    setListaAutocomplete(listaInicial);
  }

  return (
    <Grid container spacing={2} columns={13}>
      <Grid item xs={5}>
        <Autocomplete
          id="grupoProdutoId"
          name="grupoProdutoId"
          options={grupoProdutoList}
          noOptionsText="Sem opções"
          autoHighlight
          getOptionLabel={(option) => (option ? option.descricao : "")}
          disabled={filtrarTodosProdutos}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Grupo de Produto"
              variant="outlined"
              margin="normal"
              disabled={filtrarTodosProdutos}
              error={inputErros.get("grupoProdutoId")}
            />
          )}
          onChange={(_, newValue) => {
            setInputErro([]);
            onChangeAutocomplete("grupoProdutoId", newValue);
          }}
          value={value.autoComplete(grupoProdutoList, filtros?.grupoProdutoId)}
        />
      </Grid>
      <Grid item xs={5}>
        <Autocomplete
          multiple
          id="etiquetasId"
          name="etiquetasId"
          options={etiquetas}
          noOptionsText="Sem opções"
          autoHighlight
          getOptionLabel={(option) => {
            return (
              <div>
                <i
                  className="ph-fill ph-bookmark-simple"
                  style={{ color: `${option?.cor}`, marginRight: "10px" }}
                ></i>
                {option?.descricao}
              </div>
            );
          }}
          onChange={(_, newValue) =>
            onChangeMultiple(
              newValue,
              "etiquetasId",
              listaEtiquetasInicial,
              setEtiquetas
            )
          }
          value={value.multiple(listaEtiquetasInicial, filtros?.etiquetasId)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              required
              margin="normal"
              label="Etiquetas"
            />
          )}
        />
      </Grid>
      <Grid item xs={3} alignContent="center" alignItems="center">
        <FormControlLabel
          label="Filtrar todos Produtos"
          control={
            <Checkbox
              id="filtrarTodosProdutos"
              name="filtrarTodosProdutos"
              checked={filtrarTodosProdutos}
              onChange={handleChangeCheckbox}
              color="primary"
            />
          }
        />
      </Grid>
    </Grid>
  );
};

export default FiltrosConsultaFiscalStep;
