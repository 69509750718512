import {
  Button,
  Dialog,
  DialogContent,
  CardHeader,
  CardContent,
  CardActions,
} from "../../../components";
import { formatDate, unformatPrice } from "../../../utils";

const FiltroAvancadoDialog = ({
  openFiltroAvancadoDialog,
  setOpenFiltroAvancadoDialog,
  filtrosAvancados,
  setFiltrosAvancados,
  handlePesquisaAvancada,
  FormFiltroAvancado,
}) => {
  const onChangeInput = (event) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [event.target.name]: event.target.value || null,
    });
  };

  const onBlurInput = (event) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [event.target.name]: unformatPrice(event.target.value) || null,
    });
  };

  const onChangeAutocomplete = (name, value) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [name]: value ? value.id : undefined,
    });
  };

  const valueAutocomplete = (lista, valueName) => {
    const objeto = lista.find(
      (objeto) => objeto.id === filtrosAvancados[valueName]
    );
    return objeto ? objeto : undefined;
  };

  const onChangeDate = (name, date) => {
    const isValidDate = (date) => date instanceof Date && !isNaN(date);
    if (!date || !isValidDate(date)) {
      date = "";
    }
    if (
      date &&
      date.getDate() !== undefined &&
      date.getMonth() !== undefined &&
      date.getFullYear() > 2000
    ) {
      setFiltrosAvancados((prevState) => {
        const filtro = { ...prevState };
        if (
          name.includes("Start") &&
          (!filtro[name.replace("Start", "End")] ||
            filtro[name.replace("Start", "End")] === "")
        ) {
          filtro[name.replace("Start", "End")] = formatDate.toSend(date);
        }
        if (
          name.includes("End") &&
          (!filtro[name.replace("End", "Start")] ||
            filtro[name.replace("End", "Start")] === "")
        ) {
          filtro[name.replace("End", "Start")] = formatDate.toSend(date);
        }
        filtro[name] = formatDate.toSend(date);
        return filtro;
      });
    }
  };

  const valueInput = (valueName) => {
    return filtrosAvancados[valueName] ? filtrosAvancados[valueName] : "";
  };

  const valueDate = (valueName) => {
    return filtrosAvancados[valueName] ? filtrosAvancados[valueName] : null;
  };

  const onChangeMultiple = (
    valores,
    name,
    listaInicial,
    setListaAutocomplete
  ) => {
    if (!valores?.length) {
      resetarFiltrosEtiqueta(name, listaInicial, setListaAutocomplete);
      return;
    }
    setFiltrosAvancados((prevState) => ({
      ...prevState,
      [name]: valores.map(({ id }) => id),
    }));
    updateListaEtiqueta(valores, listaInicial, setListaAutocomplete);
  };

  const updateListaEtiqueta = (valores, listaInicial, setListaAutocomplete) => {
    const listaTemp = listaInicial.filter(
      (status) => !valores.some((updateStatus) => updateStatus.id === status.id)
    );
    setListaAutocomplete(listaTemp);
  };

  function resetarFiltrosEtiqueta(name, listaInicial, setListaAutocomplete) {
    setFiltrosAvancados((prevState) => {
      const prevStateTemp = { ...prevState };
      delete prevStateTemp[name];
      return prevStateTemp;
    });
    setListaAutocomplete(listaInicial);
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={openFiltroAvancadoDialog}
      onClose={() => setOpenFiltroAvancadoDialog(false)}
    >
      <DialogContent>
        <CardHeader title="Pesquisa Avançada" />
        <CardContent>
          <FormFiltroAvancado
            valueInput={valueInput}
            valueDate={valueDate}
            onChangeDate={onChangeDate}
            valueAutocomplete={valueAutocomplete}
            onChangeAutocomplete={onChangeAutocomplete}
            onChangeInput={onChangeInput}
            onBlurInput={onBlurInput}
            onChangeMultiple={onChangeMultiple}
          />
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handlePesquisaAvancada()}
          >
            <i
              className="ph-fill ph-check-circle"
              style={{ fontSize: 22, marginRight: 10 }}
            ></i>
            Filtrar
          </Button>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => {
              setFiltrosAvancados({});
              setOpenFiltroAvancadoDialog(false);
            }}
          >
            <i
              className="ph-fill ph-x-circle"
              style={{ fontSize: 22, marginRight: 10 }}
            ></i>
            Fechar
          </Button>
        </CardActions>
      </DialogContent>
    </Dialog>
  );
};

export default FiltroAvancadoDialog;
