const valueHelper = {
  date: (date) => {
    return date ? date : null;
  },
  text: (value) => {
    return value ? value : "";
  },
  autoComplete: (lista, valor) => {
    if (Array.isArray(valor)) {
      return valor.map((id) => {
        const objeto = lista.find((objeto) => objeto?.id == id);
        return objeto ? objeto : "";
      });
    } else {
      const objeto = lista.find((objeto) => objeto?.id == valor);
      return objeto ? objeto : "";
    }
  },
  multiple: (lista, valores) => {
    if (!valores?.length) return [];
    return valores.map((valor) => lista.find((item) => item.id === valor));
  },
};

export default valueHelper;
