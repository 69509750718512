import { colors } from "@mui/material";

const white = "#FFFFFF";
const black = "#000000";
const topbar = "#EB5757";

export default {
  black,
  white,
  topbar,
  primary: {
    contrastText: white,
    main: "#2196f3",
  },
  secondary: {
    contrastText: white,
    main: "#fff",
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400],
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: colors.blue[400],
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400],
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400],
  },
  text: {
    primary: "#fff",
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  background: {
    default: "#F4F6F8",
    paper: white,
  },
  icon: "#fff",
  divider: colors.grey[300],
};
