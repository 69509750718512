export function gerarCodigoBarra(produto) {
  const { codigo, tipo } = produto;
  let codigoBarra = "1";
  if (tipo === "simples") {
    codigoBarra += codigo.padStart(8, "0") + "000";
  } else {
    const codigoSplit = codigo.split(".");
    codigoBarra +=
      codigoSplit[0].padStart(8, "0") + codigoSplit[1].padStart(3, "0");
  }
  const digitos = codigoBarra.split("").map(Number);
  codigoBarra += calcularDigitoVerificador(digitos);
  return codigoBarra;
}

function calcularDigitoVerificador(digitos) {
  let verificador = 0;
  for (let i = 0; i < digitos.length; i++) {
    verificador += (i % 2 === 0 ? 1 : 3) * digitos[i];
  }
  return 10 - (verificador % 10);
}
