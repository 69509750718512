import { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  FormControl,
  Button,
  Autocomplete,
  DataGrid,
} from "../../../../components";
import { notification, PessoaService } from "../../../../services";
import { responseErros } from "../../../../utils";
import { value } from "../../../../helpers";

const FormFornecedorComponent = ({ fornecedoresRows, setFornecedoresRows }) => {
  const [fornecedores, setFornecedores] = useState([]);
  const [fornecedor, setFornecedor] = useState({});
  const [fornecedorSelecionado, setFornecedorSelecionado] = useState({});
  const pessoaService = new PessoaService();
  const colunasFornecedor = [
    {
      field: "id",
      headerName: "Fornecedor",
      flex: 500,
      valueGetter: (params) => {
        const fornecedor = fornecedores.find((item) => item.id == params.value);
        return fornecedor?.nomeRazaoSocial;
      },
    },
    {
      field: "codigoProduto",
      headerName: "Código Produto Fornecedor",
      flex: 500,
    },
    {
      field: "quantidadeEmbalagem",
      headerName: "Quantidade Embalagem",
      flex: 500,
    },
  ];

  useEffect(() => {
    buscarFornecedores();
  }, []);

  const buscarFornecedores = () => {
    const filtro = {
      nonPaginated: true,
      tiposPessoaId: 2,
    };
    pessoaService.getAll(filtro).then((result) => {
      if (!result.isAxiosError) {
        setFornecedores(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const handleChangeAutocomplete = (value) => {
    setFornecedorSelecionado(value ? value : undefined);
  };

  const handleChangeInput = (event) => {
    setFornecedor({
      ...fornecedor,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeInputNumber = (event) => {
    if (event.target.value < 0) {
      event.target.value = 0;
    }
    setFornecedor({
      ...fornecedor,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmitFornecedor = (event) => {
    event.preventDefault();
    const forcecedorCadastrado = fornecedoresRows.find((item) => {
      if (item.id == fornecedorSelecionado?.id) return item;
    });
    if (forcecedorCadastrado) {
      notification.alertaGenericos("Fornecedor já cadastrado");
    } else {
      const fornecedorCompleto = Object.assign(
        fornecedor,
        fornecedorSelecionado
      );
      setFornecedoresRows((itens) => [...itens, fornecedorCompleto]);
      setFornecedor({});
      setFornecedorSelecionado({});
      notification.cadastroSucesso();
    }
  };

  const onClickDeleteFornedor = async (idFornecedor) => {
    const data = fornecedoresRows.filter((item) => item.id !== idFornecedor);
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      "Tem certeza que deseja excluir este fornecedor?"
    );
    if (retornoAlerta.isConfirmed) {
      setFornecedoresRows(data);
    }
  };

  return (
    <form onSubmit={(event) => onSubmitFornecedor(event)}>
      <Grid container spacing={2} alignItems="center" sx={{ mb: "2rem" }}>
        <Grid item xs={4}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <Autocomplete
              id="fornecedor"
              name="fornecedor"
              options={fornecedores}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => option?.nomeRazaoSocial ?? ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Fornecedor"
                  required
                  variant="outlined"
                />
              )}
              onChange={(e, value) => handleChangeAutocomplete(value)}
              value={
                value.autoComplete(fornecedores, fornecedorSelecionado?.id) ??
                ""
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="codigoProduto"
            name="codigoProduto"
            label="Código Produto Fornecedor"
            variant="outlined"
            margin="normal"
            fullWidth
            value={fornecedor.codigoProduto ?? ""}
            onChange={(event) => handleChangeInput(event)}
            required
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="quantidadeEmbalagem"
            name="quantidadeEmbalagem"
            label="Quantidade Embalagem"
            variant="outlined"
            margin="normal"
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            value={fornecedor.quantidadeEmbalagem ?? ""}
            onChange={(event) => handleChangeInputNumber(event)}
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            size="large"
            variant="contained"
            type="submit"
            fullWidth
            color="primary"
            sx={{ height: "52px", mt: "0.5rem" }}
          >
            <i
              className="ph-fill ph-check-circle"
              style={{ fontSize: 22, marginRight: 10, color: "white" }}
            ></i>
            Adicionar
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <DataGrid
            rows={fornecedoresRows}
            columns={colunasFornecedor}
            deleteShow
            rowCount={fornecedoresRows.length}
            onClickDelete={onClickDeleteFornedor}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default FormFornecedorComponent;
