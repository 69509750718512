import { useState } from "react";
import {
  Button,
  CardHeader,
  DialogContent,
  Step,
  StepLabel,
  Stepper,
  DialogActions,
  Dialog,
  Grid,
} from "../../../components";
import ConsultaFiscalStepper from "./components/consulta-fiscal-stepper.component";
import { ConsultasLoteService, notification } from "../../../services";

const consultasLoteService = new ConsultasLoteService();
const AddConsultaLoteView = ({
  openDialog,
  handleCloseDialog,
  filtros,
  setFiltros,
  filtrarTodosProdutos,
  setFiltrarTodosProdutos,
}) => {
  const steps = ["Filtros de Produtos", "Visualizar Lista Produtos"];
  const [activeStep, setActiveStep] = useState(0);
  const [produtos, setProdutos] = useState([]);

  const handleAvancarStep = () => {
    setActiveStep((prevState) => prevState + 1);
  };

  const handleVoltarStep = () => {
    setActiveStep((prevState) => (prevState - 1 < 0 ? 0 : prevState - 1));
  };

  const handleFinalizarConsulta = () => {
    consultasLoteService.cadastrar(filtros).then((result) => {
      if (!result.isAxiosError) {
        notification.cadastroSucesso();
        handleCloseDialog();
      }
    });
  };

  return (
    <Dialog
      maxWidth={activeStep >= 1 ? "xl" : "md"}
      fullWidth
      open={openDialog}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        <CardHeader title="consulta fiscal em lote" />
        <Stepper activeStep={activeStep} sx={{ mt: 3, mb: 3 }}>
          {steps.map((step) => (
            <Step key={step}>
              <StepLabel>{step}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Grid sx={{ m: 1 }}>
          <ConsultaFiscalStepper
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            produtos={produtos}
            setProdutos={setProdutos}
            filtros={filtros}
            setFiltros={setFiltros}
            filtrarTodosProdutos={filtrarTodosProdutos}
            setFiltrarTodosProdutos={setFiltrarTodosProdutos}
          />
        </Grid>
        <DialogActions sx={{ mt: 3 }}>
          <Button
            variant="contained"
            color="padrao"
            onClick={handleCloseDialog}
          >
            fechar
          </Button>
          {activeStep !== 0 ? (
            <Button variant="contained" onClick={handleVoltarStep}>
              <i
                className="ph-fill ph-caret-left"
                style={{ color: "#fff", fontSize: 18 }}
              ></i>
              Voltar
            </Button>
          ) : (
            <Button variant="contained" onClick={handleAvancarStep}>
              Visualizar Produtos
            </Button>
          )}
          {activeStep === steps.length - 1 && (
            <Button
              variant="contained"
              color="success"
              onClick={handleFinalizarConsulta}
            >
              Finalizar
            </Button>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default AddConsultaLoteView;
