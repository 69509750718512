import { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Autocomplete,
  DataGrid,
  IconButton,
  Tooltip,
} from "../../../../components";
import { notification, ModeloGradeService } from "../../../../services";
import { gerarCodigoBarra, responseErros } from "../../../../utils";
import shortid from "shortid";
import { value } from "../../../../helpers";
import AddGradeDialog from "./add-grade-dialog.component";

const FormGradeComponent = ({
  id,
  produto,
  setProduto,
  produtosGradeList,
  setProdutosGradeList,
  produtoEntity,
}) => {
  const [modelosGradeList, setModelosGradeList] = useState([]);
  const [modeloGrade, setModeloGrade] = useState({});
  const [openProdutoGrade, setOpenProdutoGrade] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [produtosGradeListSelecao, setProdutosGradeListSelecao] = useState([]);
  const [produtosGradeListFiltrados, setProdutosGradeListFiltrados] = useState(
    []
  );
  const modeloGradeService = new ModeloGradeService();

  const produtoGradeColunas = [
    {
      field: "codigo",
      headerName: "Código",
      flex: 100,
    },
    {
      field: "codigoBarra",
      headerName: "Barra",
      sortable: false,
      flex: 200,
    },
    {
      field: "descricao",
      headerName: "Descrição",
      flex: 500,
    },
    {
      field: "estoque",
      headerName: "Estoque",
      flex: 100,
      valueGetter: (params) => params.row?.estoqueProduto?.estoque,
    },
    {
      id: shortid.generate(),
      field: "acoes",
      headerName: "Ações",
      sortable: false,
      flex: 140,
      disableClickEventBubbling: true,
      hideable: false,
      renderCell: (params) => {
        return (
          <div>
            {!id && (
              <IconAcoes
                idProduto={params.id}
                funcaoAcao={onClickEditProdutoGrade}
                phosporIcon="ph-fill ph-pencil"
                tooltipMessage="Editar"
              />
            )}
            {id && (
              <IconAcoes
                idProduto={params.id}
                funcaoAcao={onClickDeleteProdutoGrade}
                phosporIcon="ph-fill ph-trash"
                tooltipMessage="Excluir"
              />
            )}
            {id && (
              <IconAcoes
                idProduto={params.id}
                funcaoAcao={onClickCodigoBarraProdutoGrade}
                phosporIcon="ph-bold ph-barcode"
                tooltipMessage="Gerar Código de Barras"
              />
            )}
          </div>
        );
      },
    },
  ];

  function IconAcoes({ idProduto, funcaoAcao, phosporIcon, tooltipMessage }) {
    return (
      <IconButton onClick={() => funcaoAcao(idProduto)}>
        <Tooltip title={tooltipMessage} arrow placement="top">
          <i className={phosporIcon} style={{ fontSize: 18 }}></i>
        </Tooltip>
      </IconButton>
    );
  }

  useEffect(() => {
    if (id) {
      return buscarModeloGradeById(produto?.modeloGradeId);
    }
    return buscarModeloGrade();
  }, []);

  const onClickEditProdutoGrade = (id) => {
    const produto = produtosGradeList.find((pd) => pd.id === id);
    if (produto.id && !produto?.tipo) {
      const produtosList = produtosGradeList.filter(
        (prod) => prod.id === produto.id && !prod?.tipo
      );
      setActiveStep(1);
      setProdutosGradeListSelecao(produtosList);
      setProdutosGradeListFiltrados(produtosList);
      setOpenProdutoGrade(true);
    } else {
      notification.alertaGenericos(
        "Alteração permitida somente para produtos não cadastrados"
      );
    }
  };

  const onClickDeleteProdutoGrade = async (idProdutoGrade) => {
    const retornoAlerta = await notification.confirmacao(
      "Excluir!",
      "Tem certeza que deseja excluir"
    );
    if (retornoAlerta.isConfirmed) {
      const produtosFiltrados = produtosGradeList.filter(
        (produtoGrade) => produtoGrade.id != idProdutoGrade
      );
      produtoEntity.setProdutosGrade(produtosFiltrados);
      setProduto({ ...produtoEntity });
      setProdutosGradeList(produtosFiltrados);
    }
  };

  const onClickCodigoBarraProdutoGrade = (id) => {
    const produtosList = produtosGradeList.map((produto) => {
      if (produto.id === id) {
        return { ...produto, codigoBarra: gerarCodigoBarra(produto) };
      } else {
        return produto;
      }
    });
    produtoEntity.setProdutosGrade(produtosList);
    setProduto({ ...produtoEntity });
    setProdutosGradeList(produtosList);
  };

  function gerarCodigoBarraGradeEmLote() {
    const produtosList = produtosGradeList.map((produto) => ({
      ...produto,
      codigoBarra: gerarCodigoBarra(produto),
    }));
    produtoEntity.setProdutosGrade(produtosList);
    setProduto({ ...produtoEntity });
    setProdutosGradeList(produtosList);
  }

  const buscarModeloGradeById = (id) => {
    if (!id) return;
    modeloGradeService.getById(id).then((res) => {
      if (!res.isAxiosError) {
        setModelosGradeList([res.data]);
        setModeloGrade(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const buscarModeloGrade = () => {
    const filter = {
      nonPaginated: true,
    };
    modeloGradeService.getAll(filter).then((res) => {
      if (!res.isAxiosError) {
        setModelosGradeList(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const handleChangeAutocomplete = (name, value) => {
    if (value?.id) setModeloGrade(value);
    produtoEntity.setHandleChangeAutoComplete(name, value);
    setProduto({ ...produtoEntity });
  };

  const montarGrade = async (event, modeloGrade) => {
    if (event) event.preventDefault();
    if (!produto.descricao) {
      return notification.erroValidacao("Descrição do produto");
    }
    const variacoesModeloGrade = modeloGrade.variacaoModeloGrade;
    const produtosGrade = montarProdutosGrade(variacoesModeloGrade);
    setProdutosGradeListSelecao(produtosGrade);
    setOpenProdutoGrade(true);
  };

  const montarProdutosGrade = (
    variacoesModeloGrade,
    valorVariacaoPai,
    linhas,
    index
  ) => {
    if (!linhas) {
      linhas = [];
    }
    if (!index) {
      index = 0;
    }
    variacoesModeloGrade[index].valorVariacaoModeloGrade.forEach(
      (valorVariacao) => {
        if (variacoesModeloGrade.length == index + 1) {
          let descricao = "";
          if (produto.descricao) {
            descricao = `${produto.descricao} ${valorVariacao.valor}`;
            if (valorVariacaoPai) {
              descricao += ` ${valorVariacaoPai.valor}`;
            }
          }
          const linha = {
            id: shortid.generate(),
            codigoBarra: "",
            descricao,
            [`eixo${variacoesModeloGrade[index].tipoEixo.toUpperCase()}`]:
              valorVariacao.id,
          };
          if (valorVariacaoPai) {
            linha[
              `eixo${variacoesModeloGrade[index - 1].tipoEixo.toUpperCase()}`
            ] = valorVariacaoPai.id;
          }
          const linhaValida = produtosGradeList.find(
            (prod) => prod.codigoBarra && prod.descricao === linha.descricao
          );
          if (!linhaValida?.id) {
            linhas.push(linha);
          }
        } else {
          montarProdutosGrade(
            variacoesModeloGrade,
            valorVariacao,
            linhas,
            index + 1
          );
        }
      }
    );
    return linhas;
  };

  return (
    <>
      <form
        className="mt-4"
        onSubmit={(event) => montarGrade(event, modeloGrade)}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Autocomplete
              id="modeloGradeId"
              name="modeloGradeId"
              options={modelosGradeList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => (option.nome ? option.nome : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Modelo Grade"
                  required
                  variant="outlined"
                />
              )}
              onChange={(e, value) =>
                handleChangeAutocomplete("modeloGradeId", value)
              }
              value={value.autoComplete(
                modelosGradeList,
                produto?.modeloGradeId
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              type="submit"
              size="large"
              variant="contained"
              fullWidth
              color="primary"
              sx={{ height: "52px" }}
            >
              <i
                className="ph-fill ph-check-circle"
                style={{ fontSize: 22, marginRight: 10, color: "white" }}
              ></i>
              Aplicar Grade
            </Button>
          </Grid>
          {id && (
            <Grid item xs={3}>
              <Button
                size="large"
                variant="outlined"
                sx={{
                  height: "52px",
                }}
                fullWidth
                color="primary"
                onClick={() => gerarCodigoBarraGradeEmLote()}
              >
                <i
                  className="ph-bold ph-barcode"
                  style={{ fontSize: 22, marginRight: 10, color: "#2196F3" }}
                ></i>
                Gerar códigos de barras
              </Button>
            </Grid>
          )}
        </Grid>
      </form>
      <Grid container sx={{ mt: "2rem" }}>
        <DataGrid
          rows={produtosGradeList}
          columns={produtoGradeColunas}
          rowCount={produtosGradeList.length}
          onClickEdit={onClickEditProdutoGrade}
          onClickDelete={onClickDeleteProdutoGrade}
          disableAcoes
        />
      </Grid>
      <AddGradeDialog
        id={id}
        open={openProdutoGrade}
        setOpen={setOpenProdutoGrade}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        produtosGradeListFiltrados={produtosGradeListFiltrados}
        setProdutosGradeListFiltrados={setProdutosGradeListFiltrados}
        produtosGradeList={produtosGradeList}
        setProdutosGradeList={setProdutosGradeList}
        produtoGradeColunas={produtoGradeColunas}
        produtosGradeListSelecao={produtosGradeListSelecao}
        setProdutosGradeListSelecao={setProdutosGradeListSelecao}
      />
    </>
  );
};

export default FormGradeComponent;
