import { useState, useEffect } from "react";
import {
  Autocomplete,
  DatePicker,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "../../../../components";
import { EtiquetasService, GrupoProdutosService } from "../../../../services";
import { InputErros } from "../../../../helpers";
import { formatDate, responseErros } from "../../../../utils";
import { format } from "date-fns";

const FormFiltroAvancadoInventario = ({
  filtrosAvancados,
  setFiltrosAvancados,
  filtrosAplicadosImpressao,
  setFiltrosAplicadosImpressao,
}) => {
  const [grupoProdutoList, setGrupoProdutoList] = useState([]);
  const [inputErro, setInputErro] = useState([]);
  const [etiquetas, setEtiquetas] = useState([]);
  const [listaEtiquetasInicial, setListaEtiquetasInicial] = useState([]);
  const etiquetasService = new EtiquetasService();
  const grupoProdutosService = new GrupoProdutosService();
  const inputErros = new InputErros(inputErro, setInputErro);
  const listaTipos = [
    {
      label: "Preço de Compra",
      value: "precoCompra",
    },
    {
      label: "Preço de Custo",
      value: "precoCusto",
    },
  ];

  useEffect(() => {
    buscarEtiquetas();
    buscarGruposProduto();
  }, []);

  const valueAutocomplete = (lista, valueName) => {
    const objeto = lista.find(
      (objeto) => objeto.id === filtrosAvancados[valueName]
    );
    return objeto ? objeto : undefined;
  };

  const valueInput = (valueName) => {
    return filtrosAvancados[valueName] ? filtrosAvancados[valueName] : "";
  };

  const valueDate = (valueName) => {
    return filtrosAvancados[valueName] ? filtrosAvancados[valueName] : null;
  };

  const onChangeSelect = (event, name) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [event.target.name]: event.target.value ?? null,
    });
    if (name !== "Ordenação") {
      setFiltrosAplicadosImpressao((prevState) => ({
        ...prevState,
        [name]: event.target.value ?? null,
      }));
    }
  };

  const onChangeCheckbox = (event, name) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [event.target.name]: event.target.checked ?? null,
    });
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [name]: event.target.checked
        ? `${event.target.checked ? "Sim" : "Não"}`
        : null,
    }));
  };

  const onChangeAutocomplete = (id, value, name) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [id]: value ? value.id : undefined,
    });
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [name]: value ? value.descricao : undefined,
    }));
  };

  const onChangeInput = (event) => {
    setFiltrosAvancados({
      ...filtrosAvancados,
      [event.target.name]: event.target.value ?? undefined,
    });
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value ?? undefined,
    }));
  };

  const onChangeDate = (id, date, name) => {
    const isValidDate = (date) => date instanceof Date && !isNaN(date);
    if (!date || !isValidDate(date)) {
      date = "";
    }
    if (
      date &&
      date.getDate() !== undefined &&
      date.getMonth() !== undefined &&
      date.getFullYear() > 2000
    ) {
      const filtroAvancado = {
        ...filtrosAvancados,
        [id]: formatDate.toSend(date),
      };
      const filtroImpressao = {
        ...filtrosAplicadosImpressao,
        [name]: format(date, "dd/MM/yyyy"),
      };
      setFiltrosAvancados(filtroAvancado);
      setFiltrosAplicadosImpressao(filtroImpressao);
    }
  };

  const buscarGruposProduto = () => {
    const filtros = {
      nonPaginated: true,
    };
    grupoProdutosService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setGrupoProdutoList(result.data);
      } else {
        responseErros(result);
      }
    });
  };

  const buscarEtiquetas = async function () {
    const filtros = {
      nonPaginated: true,
      ativado: true,
      tipo: "PRODUTO",
    };
    const result = await etiquetasService.getAll(filtros);
    if (!result.isAxiosError) {
      setEtiquetas(result.data);
      setListaEtiquetasInicial(result.data);
    } else {
      responseErros(result);
    }
  };

  const onChangeMultiple = (valores) => {
    if (!valores?.length) {
      resetarFiltrosEtiqueta();
      return;
    }
    setFiltrosAvancados((prevState) => ({
      ...prevState,
      etiquetasId: valores.map(({ id }) => id),
    }));
    setFiltrosAplicadosImpressao((prevState) => ({
      ...prevState,
      ETIQUETAS: valores.map((valor) => renderInputEtiqueta(valor)),
    }));
    updateListaEtiqueta(valores);
  };

  const updateListaEtiqueta = (valores) => {
    const listaTemp = listaEtiquetasInicial.filter(
      (status) => !valores.some((updateStatus) => updateStatus.id === status.id)
    );
    setEtiquetas(listaTemp);
  };

  function resetarFiltrosEtiqueta() {
    setFiltrosAvancados((prevState) => {
      const prevStateTemp = { ...prevState };
      delete prevStateTemp.etiquetasId;
      return prevStateTemp;
    });
    setFiltrosAplicadosImpressao((prevState) => {
      const prevStateTemp = { ...prevState };
      delete prevStateTemp["ETIQUETAS"];
      return prevStateTemp;
    });
    setEtiquetas(listaEtiquetasInicial);
  }

  const renderInputEtiqueta = (option) => {
    if (!option) return;
    return (
      <div className="d-flex align-items-center">
        <i
          className="ph-fill ph-bookmark-simple"
          style={{
            color: option?.cor,
            marginRight: "10px",
            fontSize: "21px",
          }}
        ></i>
        {option?.descricao}
      </div>
    );
  };

  const getMultipleAutocompleteValues = (valores, lista) => {
    if (valores) {
      return valores.map((valor) => lista.find((item) => item.id === valor));
    }
    return [];
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            id="dataReferencia"
            name="dataReferencia"
            label="Data Referência"
            format="dd/MM/yyyy"
            value={valueDate("dataReferencia")}
            margin="normal"
            onChange={(date) =>
              onChangeDate("dataReferencia", date, "Data-Referência")
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label="Tipo Preço"
            id="tipoPreco"
            name="tipoPreco"
            variant="outlined"
            value={valueInput("tipoPreco")}
            margin="normal"
            onChange={(event) => onChangeSelect(event, "Tipo")}
          >
            <MenuItem value={null}>
              <p></p>
            </MenuItem>
            {listaTipos.map((tipo) => (
              <MenuItem key={tipo.value} value={tipo.value}>
                {tipo.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          id="etiquetasId"
          name="etiquetasId"
          options={etiquetas}
          noOptionsText="Sem opções"
          autoHighlight
          getOptionLabel={(option) => {
            return (
              <div>
                <i
                  className="ph-fill ph-bookmark-simple"
                  style={{ color: `${option?.cor}`, marginRight: "10px" }}
                ></i>
                {option?.descricao}
              </div>
            );
          }}
          onChange={(_, newValue) => onChangeMultiple(newValue)}
          value={getMultipleAutocompleteValues(
            filtrosAvancados?.etiquetasId,
            listaEtiquetasInicial
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              required
              margin="normal"
              label="Etiquetas"
            />
          )}
        />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <Autocomplete
              id="grupoProdutoId"
              name="grupoProdutoId"
              options={grupoProdutoList}
              noOptionsText="Sem opções"
              autoHighlight
              getOptionLabel={(option) => (option ? option.descricao : "")}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Grupo de Produto"
                  variant="outlined"
                  margin="normal"
                  error={inputErros.get("grupoProdutoId")}
                />
              )}
              onChange={(_, newValue) => {
                setInputErro([]);
                onChangeAutocomplete(
                  "grupoProdutoId",
                  newValue,
                  "Grupo de Produtos"
                );
              }}
              value={valueAutocomplete(grupoProdutoList, "grupoProdutoId")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Select
            label="Ordenar Por"
            id="ordenacao"
            name="ordenacao"
            variant="outlined"
            value={valueInput("ordenacao")}
            margin="normal"
            onChange={(event) => onChangeSelect(event, "Ordenação")}
          >
            <MenuItem value={null}>
              <p></p>
            </MenuItem>
            <MenuItem value="codigo">Código</MenuItem>
            <MenuItem value="produto">Descrição</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <TextField
            id="numeroPagina"
            name="numeroPagina"
            label="Nº da Página"
            variant="outlined"
            type="number"
            fullWidth
            onChange={onChangeInput}
            margin="normal"
            value={valueInput("numeroPagina")}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            label="Ignorar Estoque Zerado"
            labelPlacement="start"
            control={
              <Checkbox
                name="ignorarEstoqueZero"
                checked={
                  filtrosAvancados?.ignorarEstoqueZero
                    ? filtrosAvancados.ignorarEstoqueZero
                    : false
                }
                onChange={(event) =>
                  onChangeCheckbox(event, "Ignorar-Estoque-Zerado")
                }
                color="primary"
              />
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FormFiltroAvancadoInventario;
