import {
  ListaProdutosConsultaFiscalStep,
  FiltrosConsultaFiscalStep,
} from "./steps";

const ConsultaFiscalStepper = ({
  activeStep,
  setActiveStep,
  produtos,
  setProdutos,
  filtros,
  setFiltros,
  filtrarTodosProdutos,
  setFiltrarTodosProdutos,
}) => {
  switch (activeStep) {
    case 1:
      return (
        <ListaProdutosConsultaFiscalStep
          produtos={produtos}
          setProdutos={setProdutos}
          filtros={filtros}
          setFiltros={setFiltros}
          setActiveStep={setActiveStep}
        />
      );
    default:
      return (
        <FiltrosConsultaFiscalStep
          filtros={filtros}
          setFiltros={setFiltros}
          filtrarTodosProdutos={filtrarTodosProdutos}
          setFiltrarTodosProdutos={setFiltrarTodosProdutos}
        />
      );
  }
};

export default ConsultaFiscalStepper;
