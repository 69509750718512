import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import clsx from "clsx";
import PropTypes from "prop-types";
import { AppBar } from "@mui/material";
import {
  Toolbar,
  Badge,
  InputBase,
  IconButton,
  Typography,
  Tooltip,
} from "../../components";
import "./topbar.style.css";
import authActions from "../../store/modules/notificacao/actions";
import { MenuNotificacao, MenuSearchResultado, MenuConfig } from "./components";
import { history, SessionService } from "../../services";
import { buscarNotificacao, formatCnpj, formatCpf } from "../../utils";
import { StorageHelper } from "../../helpers";
import store from "../../config/store";

const Topbar = ({
  handleDrawerOpen,
  open,
  mensagens,
  setMensagens,
  quantidadeMensagemNaoLida,
}) => {
  const menuId = "primary-search-account-menu";
  const [empresa, setEmpresa] = useState(null);
  const [usuario, setUsuario] = useState(null);
  const [anchorElConfig, setAnchorElConfig] = useState(null);
  const [anchorElNotificacao, setAnchorElNotificacao] = useState(null);
  const [anchorSearchEl, setAnchorSearchEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const storageHelper = new StorageHelper();
  const sessionService = new SessionService();
  const dadosUsuarioAndEmpresa = sessionService.getEmpresaAndUsuario();

  useEffect(() => {
    buscarDadosEmpresa(dadosUsuarioAndEmpresa);
    if (
      !mensagens.length &&
      (store.enviroment == "production" || store.enviroment == "releasing")
    ) {
      const notificacaoStorage = storageHelper.getSession("notificacao");
      if (notificacaoStorage) {
        setMensagens(JSON.parse(notificacaoStorage));
      } else {
        buscarNotificacao(setMensagens, dadosUsuarioAndEmpresa.usuario.id);
      }
    }
  }, []);

  const buscarDadosEmpresa = (dadosUsuarioAndEmpresa) => {
    setEmpresa(dadosUsuarioAndEmpresa.empresa);
    setUsuario(dadosUsuarioAndEmpresa.usuario);
  };

  const handleProfileConfigOpen = (event) => {
    setAnchorElConfig(event.currentTarget);
  };

  const handleNotificacaoOpen = (event) => {
    if (mensagens.length) {
      setAnchorElNotificacao(event.currentTarget);
    }
  };

  const handleSearchMenuOpen = (event) => {
    event.preventDefault();
    setAnchorSearchEl(event.currentTarget);
  };

  const handleDrawerTranstion = async () => {
    await handleDrawerOpen();
  };

  return (
    <>
      <AppBar
        className={
          clsx("appBar") && clsx("topDrwPaper", !open && "topDrwPaperClose")
        }
      >
        <Toolbar className={open && "topbarQuery"}>
          <div className="container">
            <IconButton
              edge="start"
              color="primary"
              aria-label="open drawer"
              onClick={handleDrawerTranstion}
              style={{
                borderRadius: 8,
              }}
            >
              {open ? (
                <i
                  className="ph-fill ph-dots-three-outline-vertical"
                  style={{ fontSize: "clamp(17px, 4vw, 22px)" }}
                ></i>
              ) : (
                <i
                  className="ph-bold ph-list"
                  style={{ fontSize: "clamp(17px, 4vw, 22px)" }}
                ></i>
              )}
            </IconButton>
            <div className="search">
              <div className="searchIcon">
                <i
                  className="ph-bold ph-magnifying-glass"
                  style={{ fontSize: 18 }}
                ></i>
              </div>
              <InputBase
                onBlur={(e) => {
                  e.target.value = "";
                  setTimeout(() => {
                    setSearchTerm("");
                    setAnchorSearchEl(null);
                  }, 150);
                }}
                placeholder="o que deseja fazer?"
                onChange={(e) => {
                  e.persist();
                  handleSearchMenuOpen(e);
                  setSearchTerm(e.target.value);
                }}
                classes={{
                  root: "inputRoot",
                  input: "inputInput",
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </div>
          <div className="sectionDesktop d-flex gap-3 align-items-center">
            <Tooltip title="Notificações">
              <Badge
                badgeContent={quantidadeMensagemNaoLida}
                color="error"
                variant="dot"
                overlap="circular"
              >
                <IconButton
                  color="inherit"
                  onClick={handleNotificacaoOpen}
                  sx={{
                    backgroundColor: "#EDEEF0",
                    "&:hover": { backgroundColor: "#B6BABC" },
                  }}
                >
                  <i
                    className="ph-fill ph-bell"
                    style={{ fontSize: "clamp(17px, 4vw, 25px)" }}
                  ></i>
                </IconButton>
              </Badge>
            </Tooltip>
            <Tooltip title="Configurações">
              <IconButton
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleProfileConfigOpen}
                color="inherit"
                sx={{
                  backgroundColor: "#EDEEF0",
                  "&:hover": { backgroundColor: "#B6BABC" },
                }}
              >
                <i
                  className="ph-fill ph-gear"
                  style={{ fontSize: "clamp(17px, 4vw, 25px)" }}
                ></i>
              </IconButton>
            </Tooltip>
            <Tooltip
              title={
                <span style={{ textTransform: "capitalize" }}>
                  {usuario?.nome}
                </span>
              }
            >
              <IconButton
                onClick={() => history.push(`/meu-usuario`)}
                color="inherit"
                sx={{
                  backgroundColor: "#EDEEF0",
                  "&:hover": { backgroundColor: "#B6BABC" },
                }}
              >
                <i
                  className="ph-fill ph-user-circle"
                  style={{ fontSize: "clamp(17px, 4vw, 25px)" }}
                ></i>
              </IconButton>
            </Tooltip>
            {empresa && (
              <IconButton
                className="me-1"
                style={{
                  padding: 10,
                  borderRadius: 12,
                  boxShadow: "0px 0px 56px rgba(0, 0, 0, 0.08)",
                }}
                color="inherit"
                onClick={() => history.push(`/painel-controle/empresas`)}
              >
                <i
                  className="ph-fill ph-buildings"
                  style={{ fontSize: "clamp(17px, 4vw, 25px)" }}
                ></i>
                {!open || empresa.fantasia?.length <= 40 ? (
                  <div className="top-bar-empresa-label">
                    <Typography
                      style={{
                        color: `${"#494C62"}`,
                        textTransform: "uppercase",
                      }}
                      variant="caption"
                    >
                      {empresa.fantasia}
                    </Typography>
                    <Typography
                      style={{
                        color: "#494C62",
                        textTransform: "uppercase",
                      }}
                      variant="caption"
                    >
                      {empresa.cnpjCpf?.length == 11
                        ? formatCpf(empresa.cnpjCpf)
                        : formatCnpj(empresa.cnpjCpf)}
                    </Typography>
                  </div>
                ) : null}
              </IconButton>
            )}
          </div>
          <MenuConfig
            anchorEl={anchorElConfig}
            setAnchorEl={setAnchorElConfig}
            usuario={usuario}
          />
          <MenuNotificacao
            usuarioId={dadosUsuarioAndEmpresa.usuario.id}
            mensagens={mensagens}
            setMensagens={setMensagens}
            setAnchorElNotificacao={setAnchorElNotificacao}
            quantidadeMensagemNaoLida={quantidadeMensagemNaoLida}
            anchorElNotificacao={anchorElNotificacao}
          />
          {anchorSearchEl && (
            <>
              <MenuSearchResultado
                searchTerm={searchTerm}
                setAnchorSearchEl={setAnchorSearchEl}
              />
            </>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  mensagens: state.notificacao.mensagens,
  quantidadeMensagemNaoLida: state.notificacao.quantidadeMensagemNaoLida,
});

const mapDispatchToProps = (dispatch) => ({
  setMensagens: (mensagens) => dispatch(authActions.setMensagens(mensagens)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Topbar));
