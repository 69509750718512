import { history, notification } from "../services";
import isEqual from "lodash/isEqual";

const voltar = async (formState = {}, reload) => {
  if (formState?.inicial && formState?.final) {
    if (!isEqual(formState?.inicial, formState?.final)) {
      const confirmacaoAlerta = await notification.confirmacaoGenericos({
        title: "Existem informações lançadas/alteradas.",
        text: "Deseja sair sem salvar?",
        icon: "warning",
        confirmButtonText: "SIM",
        cancelButtonText: "NÃO",
        dangerMode: true,
        showCancelButton: true,
      });
      if (confirmacaoAlerta.isDismissed) {
        return false;
      }
      return reload ? location.reload() : history.goBack();
    } else {
      return reload ? location.reload() : history.goBack();
    }
  } else {
    history.goBack();
  }
};

export default voltar;
