import shortid from "shortid";
import { CabecalhoMainPrint, Grid } from "../../components";
import { formatPrice } from "../../utils";
import "./table-print-resumido.css";

const TablePrintResumidoComponent = ({
  id,
  filtrosAplicadosImpressao,
  relatoriosTotais,
  totais,
  tituloRelatorio,
  print,
  columns,
  itensPorPagina,
  customHeader,
}) => {
  const relatorioId = id ?? shortid.generate();
  const count = relatoriosTotais?.length
    ? Math.ceil(relatoriosTotais.length / itensPorPagina)
    : 0;
  return (
    <div id={`relatorio-${relatorioId}`}>
      {Array.from({ length: count }).map((_, pageIndex) => {
        const paginacaoInicial = filtrosAplicadosImpressao?.numeroPagina
          ? Number(filtrosAplicadosImpressao.numeroPagina)
          : 0;
        const itens = relatoriosTotais?.length
          ? relatoriosTotais.slice(
              pageIndex === 0
                ? pageIndex
                : itensPorPagina + (pageIndex - 1) * itensPorPagina,
              pageIndex === 0
                ? itensPorPagina
                : itensPorPagina + pageIndex * itensPorPagina
            )
          : [];
        return (
          <div
            id={`pagina-${pageIndex + 1}-relatorio-${relatorioId}`}
            key={pageIndex}
            className={!print ? "report-container" : "print-container"}
          >
            <CabecalhoMainPrint
              data={{
                tituloRelatorio,
                filtrosAplicadosImpressao,
                print,
                pagina: `${pageIndex + 1 + paginacaoInicial}/${
                  count + paginacaoInicial
                }`,
              }}
            />
            <Grid
              item
              id={`pagina-${pageIndex + 1}-tabela-${relatorioId}`}
              className="tabela-relatorio"
              md={12}
              xs={12}
            >
              {customHeader}
              <table id="table-relatorio" className="table table-borderless">
                <thead className="table-primary">
                  <tr>
                    {columns.map((column, index) => (
                      <th
                        key={index}
                        style={{
                          textAlign: column?.align || "start",
                          width: column?.width,
                        }}
                      >
                        {column.header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {itens.map((item, index) => (
                    <tr key={index}>
                      {columns.map((column, columnIndex) => (
                        <td key={columnIndex}>
                          <div
                            style={{
                              textAlign: column.align || "start",
                            }}
                          >
                            {column.render
                              ? column.render(item)
                              : item[column.field]}
                          </div>
                        </td>
                      ))}
                    </tr>
                  ))}
                  {pageIndex + 1 === count && totais && (
                    <tr className="table-primary">
                      {columns.map((column, columnIndex) => (
                        <td key={columnIndex}>
                          <div
                            style={{
                              textAlign: column.align || "start",
                            }}
                          >
                            {totais[column.fieldTotal] >= 0 ? (
                              <b>
                                {column.renderTotal
                                  ? column.renderTotal(totais)
                                  : formatPrice(
                                      totais[column.fieldTotal],
                                      false
                                    )}
                              </b>
                            ) : null}
                          </div>
                        </td>
                      ))}
                    </tr>
                  )}
                </tbody>
              </table>
            </Grid>
          </div>
        );
      })}
    </div>
  );
};

export default TablePrintResumidoComponent;
