import { Button } from "../../components";
import "./botao-voltar.css";
import { voltar } from "../../utils";

const BotaoVoltar = ({ onClickVoltar }) => {
  return (
    <Button
      variant="text"
      color="padrao"
      onClick={() => (onClickVoltar ? onClickVoltar() : voltar())}
    >
      <i
        className="ph-fill ph-arrow-left"
        style={{ color: "#494C62", fontSize: 23 }}
      ></i>
    </Button>
  );
};
export default BotaoVoltar;
