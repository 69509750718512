import SiafLiteApiRepository from "../../external/siafLiteApi";

class ConsultasLoteService {
  constructor() {
    this.urlBase = "/estoque/consultas-lote";
  }

  getById(id) {
    return new SiafLiteApiRepository(this.urlBase).getById(id);
  }

  getAll(filter) {
    return new SiafLiteApiRepository(this.urlBase).get(filter);
  }

  cadastrar(body) {
    return new SiafLiteApiRepository(this.urlBase).post(body);
  }

  cancelar(id) {
    return new SiafLiteApiRepository(this.urlBase).put(id);
  }
}

export default ConsultasLoteService;
