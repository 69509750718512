import { TablePrintResumidoComponent } from "../../../../components";
import { formatNomes } from "../../../../utils";

const PrintEstoque = ({
  tituloRelatorio,
  relatoriosTotais,
  filtrosAplicadosImpressao,
  print,
}) => {
  const columns = [
    {
      header: "Cod.",
      field: "codigo",
    },
    {
      header: "Produto",
      field: "produto",
      render: (value) =>
        value?.produto && formatNomes(value.produto.substring(0, 50)),
    },
    {
      header: "Entrada",
      field: "entrada",
      align: "end",
    },
    {
      header: "Saída",
      field: "saida",
      align: "end",
    },
    {
      header: "Saldo",
      field: "saldo",
      align: "end",
    },
  ];
  return (
    <TablePrintResumidoComponent
      filtrosAplicadosImpressao={filtrosAplicadosImpressao}
      relatoriosTotais={relatoriosTotais}
      tituloRelatorio={tituloRelatorio}
      print={print}
      columns={columns}
      itensPorPagina={31}
    />
  );
};

export default PrintEstoque;
