import { format } from "date-fns";
import { DataGrid, Grid, Icon, Tooltip, Typography } from "../../../components";
import { ListLayout } from "../../../layouts";
import { useEffect, useState } from "react";
import AddConsultaLoteView from "../add-consulta-lote/add-consulta-lote.view";
import {
  ConsultasLoteService,
  notification,
  SessionService,
} from "../../../services";
import { responseErros } from "../../../utils";

const sessionService = new SessionService();
const consultasLoteService = new ConsultasLoteService();
const ListConsultaLoteView = () => {
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [filtros, setFiltros] = useState({});
  const [filtrarTodosProdutos, setFiltrarTodosProdutos] = useState(false);
  const [listaConsultasLote, setListaConsultasLote] = useState([]);
  const [countListaConsultasLote, setCountListaConsultasLote] = useState(0);
  const status = [
    {
      id: 1,
      nome: "Em Processamento",
      color: "#EA9D2B",
    },
    {
      id: 2,
      nome: "Concluida",
      color: "#4FBB53",
    },
    {
      id: 3,
      nome: "Cancelada",
      color: "#DC3545",
    },
  ];
  const colunas = [
    {
      field: "status",
      headerName: "Status",
      flex: 50,
      renderCell: (params) => {
        switch (params.value) {
          case "CONCLUIDA":
            return (
              <Tooltip title="Concluida" arrow placement="right">
                <Icon>
                  <i
                    className="ph-fill ph-circle"
                    style={{ color: "#4FBB53", fontSize: 18 }}
                  ></i>
                </Icon>
              </Tooltip>
            );
          case "CANCELADA":
            return (
              <Tooltip title="Cancelada" arrow placement="right">
                <Icon>
                  <i
                    className="ph-fill ph-circle"
                    style={{ color: "#DC3545", fontSize: 18 }}
                  ></i>
                </Icon>
              </Tooltip>
            );
          default:
            return (
              <Tooltip title="Em Processamento" arrow placement="right">
                <Icon>
                  <i
                    className="ph-fill ph-circle"
                    style={{ color: "#EA9D2B", fontSize: 18 }}
                  ></i>
                </Icon>
              </Tooltip>
            );
        }
      },
    },
    {
      field: "createdAt",
      headerName: "Data cadastro",
      flex: 500,
      valueGetter: (params) =>
        format(new Date(params.value), "dd/MM/yyyy HH:mm"),
    },
  ];

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setFiltros({});
    setFiltrarTodosProdutos(false);
    setOpenDialog(false);
    buscarConsultasLote();
  };

  const onClickCancelarConsultaLote = async function (id) {
    const consultaLote = listaConsultasLote.find((item) => item.id === id);
    if (consultaLote) {
      if (consultaLote?.status === "CANCELADA") {
        return notification.alertaGenericos(
          "Essa consulta fiscal em lote já está cancelada"
        );
      }
      const retornoAlerta = await notification.confirmacao(
        "Cancelar!",
        "Tem certeza que deseja cancelar essa consulta fiscal em lote?"
      );
      if (retornoAlerta.isConfirmed) {
        setLoading(true);
        const response = await consultasLoteService.cancelar(consultaLote?.id);
        setLoading(false);
        if (!response.isAxiosError) {
          notification.alteracaoSucesso();
          buscarConsultasLote();
        } else {
          responseErros(response);
        }
      }
    }
  };

  const buscarConsultasLote = (filtros) => {
    setLoading(true);
    consultasLoteService.getAll(filtros).then((result) => {
      if (!result.isAxiosError) {
        setLoading(false);
        setListaConsultasLote(result.data.rows);
        setCountListaConsultasLote(result.data.count);
      } else {
        setLoading(false);
        responseErros(result);
      }
    });
  };

  const sendServerDatagrid = (props) => {
    if (props.tipoFiltro == "simples") {
      buscarConsultasLote(props.filtros);
    }
  };

  useEffect(() => {
    buscarConsultasLote();
  }, []);

  return (
    <ListLayout
      title="Consulta Fiscal em Lote"
      onClickCadastrar={handleClickOpenDialog}
      permissaoButton={sessionService.getEmpresa()?.autorizarConsultaConfisped}
      loading={loading}
    >
      <Grid container spacing={2} sx={{ mb: 3, pl: 1.7 }}>
        {status.map((status) => (
          <span className="d-flex m-3" key={status.id}>
            <i
              className="ph-fill ph-circle"
              style={{ color: status.color, fontSize: 18, marginRight: 8 }}
            ></i>
            <Typography className="me-3">{status.nome}</Typography>
          </span>
        ))}
      </Grid>
      <DataGrid
        rows={listaConsultasLote}
        columns={colunas}
        rowCount={countListaConsultasLote}
        paginationMode="server"
        sendServer={(props) => sendServerDatagrid(props)}
        onClickDelete={onClickCancelarConsultaLote}
        deleteShow
        noFilter
      />
      <AddConsultaLoteView
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        filtros={filtros}
        setFiltros={setFiltros}
        filtrarTodosProdutos={filtrarTodosProdutos}
        setFiltrarTodosProdutos={setFiltrarTodosProdutos}
      />
    </ListLayout>
  );
};

export default ListConsultaLoteView;
