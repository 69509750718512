import { useEffect, useState } from "react";
import {
  GruposTributarioService,
  notification,
  ProdutosService,
} from "../../../../../services";
import { responseErros } from "../../../../../utils";
import { DataGrid, Loading } from "../../../../../components";

const gruposTributarioService = new GruposTributarioService();
const produtosService = new ProdutosService();

const ListaProdutosConsultaFiscalStep = ({
  filtros,
  setFiltros,
  produtos,
  setProdutos,
  setActiveStep,
}) => {
  const [gruposTributario, setGruposTributario] = useState([]);
  const [loading, setLoading] = useState(false);
  const colunas = [
    {
      field: "codigo",
      headerName: "Código",
      flex: 110,
      valueGetter: (params) => Number(params.value),
    },
    {
      field: "codigoBarra",
      headerName: "Cod.Barra",
      flex: 150,
    },
    {
      field: "descricao",
      headerName: "Descrição",
      flex: 600,
      filtrar: true,
    },
    {
      field: "ncmCodigo",
      headerName: "NCM",
      sortable: false,
      flex: 200,
    },
    {
      field: "grupoTributacaoId",
      headerName: "CSO",
      flex: 200,
      valueGetter: (params) =>
        gruposTributario.find(({ id }) => id === params.value)
          ?.csoSimplesNacional,
    },
  ];

  const buscarProdutos = async (query) => {
    const filtroTemp = {
      ...query,
      ...filtros,
      produtoGrade: true,
    };
    setLoading(true);
    const res = await produtosService.getAll(filtroTemp);
    if (!res.isAxiosError) {
      const result = res.data.rows.filter((produto) => produto.codigoBarra);
      if (!result.length) return handleConsultaInit();
      setProdutos(result);
    } else {
      responseErros(res);
    }
    setLoading(false);
  };

  const buscarGruposTributario = () => {
    const filter = {
      nonPaginated: true,
    };
    gruposTributarioService.getAll(filter).then((res) => {
      if (!res.isAxiosError) {
        setGruposTributario(res.data);
      } else {
        responseErros(res);
      }
    });
  };

  const handleConsultaInit = () => {
    setProdutos([]);
    setFiltros({});
    setActiveStep(0);
    return notification.alertaGenericos(
      "Dados não encontrados para os filtros selecionados"
    );
  };

  useEffect(() => {
    buscarProdutos();
    buscarGruposTributario();
  }, []);

  return (
    <>
      <DataGrid
        rows={produtos}
        columns={colunas}
        rowCount={produtos.length}
        paginationMode="server"
        sendServer={({ filtros }) => buscarProdutos(filtros)}
        disableAcoes
        noFilter
        nonPaginated
      />
      <Loading loading={loading} />
    </>
  );
};

export default ListaProdutosConsultaFiscalStep;
